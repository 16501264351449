import React, { Component } from "react";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Layout from "../components/layout";
// import Pagination from "../components/pagination";
import RealizationsGrid from "../components/realizations_grid";
import RealizationsTable from "../components/realizations_table";

class Realizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      realizations: this.props.pageContext.realizations,
      data: this.props.pageContext.data,
      lang: this.props.pageContext.lang,
      breadcrumbs: this.props.pageContext.breadcrumbs,
      menu: this.props.pageContext.menu,
      footerMenu: this.props.pageContext.footerMenu,
    };
  }

  render() {
    const {
      realizations,
      data,
      lang,
      breadcrumbs,
      menu,
      footerMenu,
    } = this.state;

    return (
      <Layout menu={menu} footerMenu={footerMenu} title={data.seoTitle}>
        <div className="bg-gradient">
          <Header lang={lang} hrefLang={data.hrefLang} />
          <Breadcrumbs data={breadcrumbs} lang={lang} />
          <div className="main-wrapper-md">
            <section className="section-header-image">
              <div className="text-holder">
                <header className="section-header red-line">
                  <h1>
                    {data.realizations_h1.split(" ")[0]}{" "}
                    <strong>{data.realizations_h1.split(" ").pop()}</strong>
                  </h1>
                </header>
                <p>{data.realizations_description}</p>
              </div>
              <div className="image-holder">
                <img
                  src={require("../images/realizations-header.svg")}
                  alt=""
                />
              </div>
            </section>
            <section className="realizations-list">
              <RealizationsGrid
                realizations={realizations}
                slug={`/${lang}/${data.slug}`}
              />
              {/* <Pagination /> */}
            </section>
          </div>
          <div className="bg-gradient">
            <div className="main-wrapper-md">
              <RealizationsTable realizations={realizations} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Realizations;
