import React, { Component } from "react";
import Select from "react-select";
import { uniqWith, isEqual, orderBy } from "lodash";
import MarkdownRenderer from "react-markdown-renderer";

class RealizationsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      realizations: this.props.realizations,
      yearDefault: new Date().getFullYear().toString(),
    };
  }
  render() {
    const { realizations, yearDefault } = this.state,
      selectStyles = {
        control: (styles, { isFocused }) => ({
          ...styles,
          backgroundColor: "#fff",
          border: isFocused
            ? "2px solid #b2000b"
            : "2px solid #E0E0E0",
          borderRadius: "0",
        }),
        option: (
          styles,
          { isDisabled, isFocused, isSelected }
        ) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? "#b2000b"
              : isFocused
              ? "rgba(178,0,11,.5)"
              : null,
            color: isDisabled
              ? null
              : isSelected
              ? "#fff"
              : isFocused
              ? "#fff"
              : null,
          };
        },
        indicatorSeparator: (styles) => ({
          ...styles,
          display: "none",
        }),
      };
    let realizationsTable = [],
      realizationSelect = [];
    realizations.map(({ realization_year }) => {
      realizationsTable.push({
        items: [],
        year: realization_year,
      });
      realizationSelect.push({
        value: realization_year,
        label: realization_year,
      });
      return realizationsTable, realizationSelect;
    });
    realizationsTable = uniqWith(
      realizationsTable,
      isEqual
    );
    realizationSelect = uniqWith(
      realizationSelect,
      isEqual
    );
    realizationSelect = orderBy(
      realizationSelect,
      (el) => -el.value
    );
    realizations.map((item) =>
      realizationsTable[
        realizationsTable.findIndex(
          (element) =>
            item.realization_year === element.year
        )
      ].items.push(item)
    );
    return (
      <section className="realizations-table">
        <header className="section-header red-line">
          <h2>
            Pozostałe <strong>realizacje</strong>
          </h2>
          <div className="realizations-select">
            <span className="select-name">Wybierz rok</span>
            <Select
              styles={selectStyles}
              defaultValue={realizationSelect[0]}
              className="year-select"
              id="realization_year"
              options={realizationSelect}
              onChange={(e) =>
                this.setState({ yearDefault: e.value })
              }
            />
          </div>
        </header>
        <div className="realizations-table-wrapper">
          {realizationsTable &&
            realizationsTable
              .find(({ year }) => year === yearDefault)
              .items.map(
                ({
                  title,
                  place,
                  id,
                  realizations_list,
                }) => {
                  return (
                    <div key={id} className="single-row">
                      <div className="box box-lg">
                        <h3>{title}</h3>
                      </div>
                      <div className="box box-sm">
                        <span>{place}</span>
                      </div>
                      <div className="box box-xl">
                        <MarkdownRenderer
                          markdown={realizations_list}
                        />
                      </div>
                    </div>
                  );
                }
              )}
        </div>
      </section>
    );
  }
}

export default RealizationsTable;
