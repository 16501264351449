import React, { Component } from "react";
import RealizationBox from "./realization_box";

class RealizationsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { realizations, slug } = this.props;

    return (
      <div className="realizations-list-wrapper">
        {realizations.length > 0 &&
          realizations
            .filter(
              ({ images, realization_year }) =>
                !!images && !!realization_year
            )
            .sort(
              (a, b) =>
                b.realization_year - a.realization_year
            )
            .map(({ id, title, images, place }) => (
              <RealizationBox
                key={id}
                img={images}
                title={title}
                place={place}
                href={slug}
              />
            ))}
      </div>
    );
  }
}

export default RealizationsGrid;
